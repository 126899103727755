<template>
  <div style="height: 100%;">
    <el-amap
      vid="amapDemo"
      :center="center"
      cursor="default"
      mapStyle="amap://styles/95a2766c67523ecb6ac7b9c1f80f239e"
      class="amap-demo"
      :zoom="zoom"
      :amap-manager="amapManager"
      :events="events"
      ref="map"
    >
      <div
        class="search-wrap"
        :style="
          isFocus
            ? 'border: 1px solid rgba(64, 158, 255, 0.6)'
            : 'border: 1px solid rgba(198, 213, 231, 0.6)'
        "
      >
        <el-dropdown :hide-on-click="true" @command="handleCommand($event)">
          <span class="el-dropdown-link">
            {{ commandName }} <span class="undeline"></span
          ></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a">车场</el-dropdown-item>
            <el-dropdown-item command="b">区域</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <a-park-select
          style="width: 138px;display: inline-block;border-color .1s cubic-bezier(.645,.045,.355,1)"
          v-if="commandName == '车场'"
          ref="parkSelect"
          :instance="this"
          parkTypeRefName="parkTypeSelect"
          operationRefName="operationSelect"
          areaRefName="cascader"
          @change="changePark"
        ></a-park-select>
        <a-cascader
          v-if="commandName == '区域'"
          ref="cascader"
          @change="handleAreaChange"
          style="width: 138px;display: inline-block;border-color .1s cubic-bezier(.645,.045,.355,1)"
        ></a-cascader>
      </div>
      <div class="full-screen-wrapper">
        <img
          src="./img/full-screen.png"
          alt=""
          @click.stop="viewMap"
          v-if="mapShow"
        />
        <img src="./img/out-screen.png" v-show="outIcon" @click.stop="outMap" />
      </div>
      <!--      车场点-->
      <el-amap-marker
        vid="parkMarkers"
        v-for="marker in parkMarkers"
        :key="marker.parkId"
        :position="marker.position"
        :content="marker.template"
        :events="marker.events"
        :offset="[-3, -3]"
      >
      </el-amap-marker>
      <!--     泊位点-->
      <el-amap-marker
        vid="berthMarkers"
        v-for="(marker, index) in berthMarkers"
        :key="index"
        :position="marker.position"
        :content="marker.content"
        :events="marker.events"
        :offset="[-3, -3]"
      >
      </el-amap-marker>
      <!-- 提示信息 -->
      <el-amap-info-window
        :position="berthData.position"
        :content="berthData.content"
        :visible="berthData.visible"
        :events="berthData.events"
      ></el-amap-info-window>
    </el-amap>
  </div>
</template>
<script>
import { AMapManager } from "vue-amap";
let amapManager = new AMapManager();
export default {
  props: ["park_id", "area_id", "areaData", "mapShow", "outIcon"],
  data() {
    return {
      // 0高位视频 1巡检车 2视频桩 3地磁 4路牙机 5PDA设备
      park_0_icon: require("./img/gwsp_online.png"),
      park_1_icon: require("./img/xjc_online.png"),
      park_2_icon: require("./img/spz_online.png"),
      park_3_icon: require("./img/dc_online.png"),
      park_4_icon: require("./img/lyj_online.png"),
      // park_5_icon: require("./img/gwsp_online.png"),
      parkMarkers: [],
      berthData: {
        position: [0, 0],
        content: "",
        events: {},
        visible: false
      },
      center: [116.395645, 39.929986],
      zoom: 8,
      amapManager: amapManager,
      events: {},
      berthMarkers: [],
      input: "",
      isFocus: false,
      commandName: "车场",
      optionsList: [],
      selectType: "",
      areaId: ""
    };
  },
  watch: {
    areaData: {
      handler(val) {
        console.log("数据数据：：", val);
        // this.zoom = 16;
        if (!this.park_id && !this.area_id) {
          this.zoom = 8;
        } else {
          this.zoom = 16;
        }
        this.getInitIcon(val);
      }
    },

    park_id: {
      handler(val) {
        console.log("车场车场：：：");
        this.zoom = 16;
        this.getParkData(); // 车场
      }
    },

    area_id: {
      handler(val) {
        console.log("区域区域：：：");
        this.zoom = 16;
        this.getAreaData(); // 区域
      }
    }
  },
  methods: {
    /**
     * 车场
     */
    getParkData() {
      this.$axios
        .get("/acb/2.0/equipmentStatistics/getParkEquipmentNumber", {
          data: {
            // parkId: this.park_id,
            // areaId: this.areaId,
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            parkId: this.$refs.parkSelect
              ? this.$refs.parkSelect.getParkId()
              : ""
          }
        })
        .then((res) => {
          if (res.state == 0) {
            this.getInitIcon(res.value);
          }
        })
        .catch(() => {
          this.parkMarkers = [];
        });
    },

    /**
     * 区域
     */
    getAreaData() {
      this.$axios
        .get("/acb/2.0/equipmentStatistics/getAreaEquipmentNumber", {
          data: {
            // parkId: this.park_id,
            // areaId: this.areaId,
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            parkId: this.$refs.parkSelect
              ? this.$refs.parkSelect.getParkId()
              : ""
          }
        })
        .then((res) => {
          this.getInitIcon(res.value);
        });
    },

    getInitIcon(value) {
      this.parkMarkers = [];
      let arrMapData = [];
      let that = this;
      value.forEach((item) => {
        if (item.longitude && item.latitude) {
          arrMapData.push(item);
        }
      });
      arrMapData.forEach((item, index) => {
        if (index === 0) {
          that.center = [
            parseInt(item.longitude) / 1000000,
            parseInt(item.latitude) / 1000000
          ];
          // that.zoom = 14;
        }
        item.equipmentNumbers.forEach((val, index) => {
          // val.longitude = item.longitude + index * 1000;
          // val.latitude = item.latitude;
          let icon = ``;
          if (val.equipmentType == -1) {
            icon += `background: url('${this.park_0_icon}') no-repeat;`;
          } else if (val.equipmentType == 1) {
            icon += `background: url('${this.park_1_icon}') no-repeat;`;
          } else if (val.equipmentType == 12) {
            icon += `background: url('${this.park_2_icon}') no-repeat;`;
          } else if (val.equipmentType == 11) {
            icon += `background: url('${this.park_3_icon}') no-repeat;`;
          } else if (val.equipmentType == 13) {
            icon += `background: url('${this.park_4_icon}') no-repeat;`;
          }
          this.parkMarkers.push({
            position: [
              parseInt(item.longitude + index * 1000) / 1000000,
              parseInt(item.latitude) / 1000000
            ],
            offset: new AMap.Pixel(0 - index, 0 - index),
            // position: [39904987 / 1000000, 116405289 / 1000000],
            template: `<div class="icon-default" style="${icon}"></div>`,
            draggable: false,
            visible: true,
            events: {
              mouseover(o) {
                that.berthData = {
                  position: [
                    parseInt(item.longitude + index * 1000) / 1000000,
                    parseInt(item.latitude) / 1000000
                  ],
                  content: `<div class="content-wrap">
                                  <span class="name">${item.areaName} ${val.totalEquipmentNumber} <br></span>
                                  <span class="type">${val.equipmentTypeName} <br></span>
                                  <span class="online">在线<span style="color: #FFF1A2">${val.onlineEquipmentNumber}</span>个<br></span>
                                  <span class="offline">离线<span>${val.offlineEquipmentNumber}</span>个<br></span>
                              </div>`,
                  events: {},
                  visible: true
                };
              },
              mouseout() {
                that.berthData.visible = false;
              }
            }
          });
        });
      });
    },

    /**
     * 聚焦
     */
    focusHandler() {
      this.isFocus = true;
    },

    /**
     * 失去焦点
     */
    blurHandler() {
      this.isFocus = false;
    },

    changePark() {
      let parkId = this.$refs.parkSelect
        ? this.$refs.parkSelect.getParkId()
        : "";
      if (parkId) {
        this.zoom = 16;
      } else {
        this.zoom = 8;
      }
      this.getParkData();
    },

    viewMap() {
      this.$emit("viewMap");
    },

    outMap() {
      this.$emit("outMap");
    },

    handleAreaChange() {
      let areaId = this.$refs.cascader ? this.$refs.cascader.getAreaId() : "";
      if (areaId) {
        this.zoom = 16;
      } else {
        this.zoom = 8;
      }
      this.getAreaData();
    },

    handleCommand(cmd) {
      this.zoom = 8;
      if (cmd == "a") {
        this.commandName = "车场";
      } else if (cmd == "b") {
        this.commandName = "区域";
      }
    }
  },
  mounted() {}
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.amap-demo {
  // height: 508px;
  position: relative;
}

>>>.icon-default {
  width: 52px;
  height: 66px;
  transform: translate(0, 0) scale(0.5);
}

.parking-icon {
  font-size: 20px;
  cursor: pointer;
}
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.search-wrap {
  width: 188px;
  line-height: 30px;
  position: absolute;
  top: 8px;
  left: 12px;
  display: block;

  .el-dropdown-link {
    padding: 6px 0px 5px 12px;
    background: #fff;
    font-size: 14px;
    color: #7A828A;
    padding-right: 1px solid #F3F3F3;
    border-right-width: 1px;
    vertical-align: middle;
    cursor: pointer;

    .undeline {
      width: 1px;
      height: 20px;
      border-left: 1px solid #f3f3f3;
      margin-left: 9px;
    }
  }

  /deep/ .el-input--small .el-input__inner {
    border: none;
    border-radius: 0;
  }
}

.full-screen-wrapper {
  position: absolute;
  bottom: 15px;
  right: 15px;
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;

  img {
    width: 12px;
    height: 12px;
    margin: 0 auto;
    padding-left: 6px;
    padding-top: 6px;
  }
}

/deep/ .content-wrap {
  span {
    font-size: 12px;
  }

  .name {
    display: block;
    color: #fff;
    padding-bottom: 8px;
    font-weight: bold;
  }

  .type, .online {
    display: block;
    margin-bottom: 6px;
    line-height: 16px;
  }
}

.park_h4_spack_icon {
  color: #ccc;
}

.park_h4_spack {
  display: inline-block;
  width: 4px;
  height: 15px;
  background: linear-gradient(225deg, #4aa1fa, #2468f2);
  border-radius: 1px;
  vertical-align: bottom;
}

/deep/ .amap-info-content {
  padding: 8px 16px;
  background: rgba(10, 18, 56, 0.52);
  border-radius: 4px;
  backdrop-filter: blur(4px);
  color: #fff;
  font-size: 12px;
}

/deep/ .amap-info-close {
  display: none;
}

/deep/ .bottom-center .amap-info-sharp {
  display: none;
}

.search-wrap /deep/ .el-input__inner {
  height: 30px;
  // line-height: 30px;
  border-radius: 0;
  border: none;
  color: #7a828a;
  font-size: 14px;
}

.search-wrap /deep/ .el-input__icon {
  line-height: 30px;
}

/deep/ .el-cascader {
  line-height: 30px;
}
</style>
