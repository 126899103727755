<template>
  <div class="ContentNewMaP">
    <div id="newMaP"></div>
    <div
     v-show="!mapShow"
      class="search-wrap"
      :style="
        isFocus
          ? 'border: 1px solid rgba(64, 158, 255, 0.6)'
          : 'border: 1px solid rgba(198, 213, 231, 0.6)'
      "
    >
      <el-dropdown :hide-on-click="true" @command="handleCommand($event)">
        <span class="el-dropdown-link">
          {{ commandName }} <span class="undeline"></span
        ></span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="a">车场</el-dropdown-item>
          <el-dropdown-item command="b">区域</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <a-park-select
        style="width: 138px;display: inline-block;border-color .1s cubic-bezier(.645,.045,.355,1)"
        v-if="commandName == '车场'"
        ref="parkSelect"
        :instance="this"
        parkTypeRefName="parkTypeSelect"
        operationRefName="operationSelect"
        areaRefName="cascader"
        @change="changePark"
      ></a-park-select>
      <a-cascader
        v-if="commandName == '区域'"
        ref="cascader"
        style="width: 138px;display: inline-block;border-color .1s cubic-bezier(.645,.045,.355,1)"
        @change="handleAreaChange"
      ></a-cascader>
    </div>
    <div class="full-screen-wrapper">
      <img
        src="./img/full-screen.png"
        alt=""
        @click.stop="viewMap"
        v-if="mapShow"
      />
      <img src="./img/out-screen.png" v-if="outIcon" @click.stop="outMap" />
    </div>
  </div>
</template>
<script>
export default {
  props: ["mapShow", "outIcon"],
  data() {
    return {
      infowindow: "",
      parkMarkers: [],
      zoomInit: 7,
      map: "",
      park_0_icon: require("./img/gwsp_online.png"),
      park_1_icon: require("./img/xjc_online.png"),
      park_2_icon: require("./img/spz_online.png"),
      park_3_icon: require("./img/dc_online.png"),
      park_4_icon: require("./img/lyj_online.png"),
      cityMarkers: "",
      commandName: "车场",
      isFocus: false,
    };
  },
  watch: {},
  methods: {
    initMap() {
      this.map = new AMap.Map("newMaP", {
        mapStyle: "amap://styles/95a2766c67523ecb6ac7b9c1f80f239e",
        zoom: this.zoomInit,
        pitch: 65,
        rotation: 0,
        center: [116.397428, 39.90923],
      });
    },
    /**
     * 地图全屏
     */
    viewMap() {
      this.$emit("viewMap");
    },
    outMap() {
      this.$emit("outMap");
    },
    /**
     * 区域
     */
    getAreaData(areaId, parkId, val) {
      let hierarchy = val;
      let zoom = 7; // 全部
      let url = "/acb/2.0/equipmentStatistics/getAreaEquipmentNumber";
      if (hierarchy == 1) {
        zoom = 7;
      }
      if (hierarchy == 2) {
        zoom = 10;
      }
      if (hierarchy == 3) {
        url = "/acb/2.0/equipmentStatistics/getParkEquipmentNumber";
        zoom = 14;
      }
      this.$axios
        .get(url, {
          data: {
            areaId: areaId || "",
            parkId: parkId || "",
          },
        })
        .then((res) => {
          this.map.setCenter([
            res.value[0].longitude / 1000000,
            res.value[0].latitude / 1000000,
          ]);
          this.getInitIcon(res.value, hierarchy);
          this.map.setZoom(zoom);
        });
    },
    getInitIcon(dataIcon, hierarchy) {
      this.map.remove(this.map.getAllOverlays("marker"));
      this.cityMarkers = [];
      let arrMapData = [];
      let parms = "equipmentNumbers";
      let title = "areaName";
      dataIcon.forEach((item) => {
        if (item.longitude && item.latitude) {
          arrMapData.push(item);
        }
      });
      if (hierarchy != 3) {
      } else {
        parms = "equipments";
        title = "parkName";
      }
      arrMapData.forEach((item, index) => {
        item[parms].forEach((val, index) => {
          let icon = ``;
          if (val.equipmentType == -1) {
            icon = `background: url('${this.park_0_icon}') no-repeat;`;
          } else if (val.equipmentType == 1) {
            icon = `background: url('${this.park_1_icon}') no-repeat;`;
          } else if (val.equipmentType == 12) {
            icon = `background: url('${this.park_2_icon}') no-repeat;`;
          } else if (val.equipmentType == 11) {
            icon = `background: url('${this.park_3_icon}') no-repeat;`;
          } else if (val.equipmentType == 13) {
            icon = `background: url('${this.park_4_icon}') no-repeat;`;
          }
          const marker = new AMap.Marker({
            map: this.map,
            position: [
              parseInt(item.longitude + (hierarchy == 3 ? (index * 9000) : (index * 90000)) / hierarchy) / 1000000,
              parseInt(item.latitude) / 1000000,
            ],
            content: `<div class="icon-default" style="${icon}"></div>`,
            extData: {},
          });
          let content = `<div class="content-wrap">
                    <span class="name">${item[title]} ${val.totalEquipmentNumber} <br></span>
                    <span class="type">${val.equipmentTypeName} <br></span>
                    <span class="online">在线<span style="color: #FFF1A2">${val.onlineEquipmentNumber}</span>个<br></span>
                    <span class="offline">离线<span>${val.offlineEquipmentNumber}</span>个<br></span>
                    </div>`;
          let infowindow = new AMap.InfoWindow({
            isCustom: true,
            content: content,
            offset: new AMap.Pixel(0, -10),
          });
          // 添加点击事件
          marker.on("click", (e) => {
            console.log("click-->", hierarchy);
            if (hierarchy == 1) {
              // 进入市级别
              this.getAreaData("281479271743488", "", 2);
            }
            if (hierarchy == 2) {
              // 进入车厂级
              this.getAreaData("281479271743489", "", 3);
            }
          });
          // 添加鼠标滑入事件
          marker.on("mouseover", (e) => {
            infowindow.open(this.map, [
              parseInt(item.longitude + (hierarchy == 3 ? (index * 9000) : (index * 90000)) / hierarchy) / 1000000,
              parseInt(item.latitude) / 1000000,
            ]);
          });
          marker.on("mouseout", (e) => {
            infowindow.close();
          });
        });
      });
    },
    handleCommand(cmd) {
      this.zoom = 8;
      if (cmd == "a") {
        this.commandName = "车场";
      } else if (cmd == "b") {
        this.commandName = "区域";
      }
    },
    handleAreaChange(val) {
      console.log("val", val);
      this.getAreaData(
        this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
        val
      );
    },
    changePark() {
      this.getAreaData(
        this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
        3
      );
    },
  },
  mounted() {
    this.initMap();
    this.handleAreaChange(1)
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.ContentNewMaP {
  width: 100%;
  height: 100%;
  position: relative;
}

#newMaP {
  width: 100%;
  height: 100%;
}

>>>.icon-default {
  width: 52px;
  height: 66px;
  transform: translate(0, 0) scale(0.5);
}

/deep/ .content-wrap {
  padding: 8px 16px;
  background: rgba(10, 18, 56, 0.52);
  border-radius: 4px;
  backdrop-filter: blur(4px);
  color: #fff;
  font-size: 12px;

  span {
    font-size: 12px;
  }

  .name {
    display: block;
    color: #fff;
    padding-bottom: 8px;
    font-weight: bold;
  }

  .type, .online {
    display: block;
    margin-bottom: 6px;
    line-height: 16px;
  }
}

.full-screen-wrapper {
  position: absolute;
  bottom: 15px;
  right: 15px;
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;

  img {
    width: 12px;
    height: 12px;
    margin: 0 auto;
    padding-left: 6px;
    padding-top: 6px;
  }
}

.search-wrap {
  width: 188px;
  line-height: 30px;
  position: absolute;
  top: 8px;
  left: 12px;
  display: block;

  .el-dropdown-link {
    padding: 6px 0px 5px 12px;
    background: #fff;
    font-size: 14px;
    color: #7A828A;
    padding-right: 1px solid #F3F3F3;
    border-right-width: 1px;
    vertical-align: middle;
    cursor: pointer;

    .undeline {
      width: 1px;
      height: 20px;
      border-left: 1px solid #f3f3f3;
      margin-left: 9px;
    }
  }

  /deep/ .el-input--small .el-input__inner {
    border: none;
    border-radius: 0;
  }
}

.search-wrap /deep/ .el-input__inner {
  height: 30px;
  // line-height: 30px;
  border-radius: 0;
  border: none;
  color: #7a828a;
  font-size: 14px;
}

.search-wrap /deep/ .el-input__icon {
  line-height: 30px;
}

/deep/ .el-cascader {
  line-height: 30px;
}
</style>
