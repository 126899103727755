<!--
 * @Author: faf
 * @desc: 设备分布情况
 * @Date: 2022-12-15 10:36:49
 * @LastEditors: faf
 * @ LastEditTime:
-->
<template>
  <div class="page1">
    <div class="content">
      <div id="home_content">
        <div class="searchWrapper" @keydown.enter.prevent="">
          <el-form
            :inline="true"
            status-icon
            label-position="right"
            :model="formInline"
            ref="formInline"
            class="demo-form-inline"
          >
            <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
            <div class="col_box">
              <div class="col_left">
                <el-form-item :label="$t('searchModule.region')" prop="areaId">
                  <a-cascader ref="cascader" @change="areaHandler"></a-cascader>
                </el-form-item>
                <el-form-item :label="$t('searchModule.Road_or_Parking_Lot')">
                  <a-park-select
                    ref="parkSelect"
                    :instance="this"
                    parkTypeRefName="parkTypeSelect"
                    operationRefName="operationSelect"
                    areaRefName="cascader"
                    @change="selectPark"
                    :slaveRelations="slaveRelations"
                  ></a-park-select>
                </el-form-item>
              </div>
              <div class="col_right">
                <el-button
                  type="primary"
                  @click="
                    pageNum = 1;
                    searchData();
                  "
                  :loading="loading"
                  icon="el-icon-search"
                  >查询
                </el-button>
                <el-button
                  type="info"
                  icon="el-icon-delete"
                  plain
                  @click="resetForm()"
                  :loading="loading"
                  >{{ $t('button.reset') }}</el-button
                >
              </div>
            </div>
          </el-form>
        </div>
        <div>
          <el-row :gutter="16" class="content1-wrap">
            <el-col :span="8">
              <div class="item-wrap">
                <graphTitle title="路内">
                  <div class="line-title">
                    <span class="online"><span>在线</span></span>
                    <span class="offline"><span>离线</span></span>
                  </div>
                </graphTitle>
                <road-item :roadData="insideRoadData" style="padding-bottom: 12px"></road-item>
                <graphTitle title="路外">
                  <div class="line-title">
                    <span class="online"><span>在线</span></span>
                    <span class="offline"><span>离线</span></span>
                  </div>
                </graphTitle>
                <road-item :roadData="outRoadData"></road-item>
              </div>
            </el-col>
            <el-col :span="16">
              <div class="item-wrap" :style="{ height: centerHeight.value }" ref="map">
                <!-- <olMap
              :park_id="$refs.parkSelect ? $refs.parkSelect.getParkId() : ''"
              :area_id="$refs.cascader ? $refs.cascader.getAreaId() : ''"
              :areaData="areaData"
              @viewMap="viewMap"
              @outMap="outMap"
              :mapShow="mapShow"
              :outIcon="outIcon"
            ></olMap> -->
                <newMaP
                  ref="newMaP"
                  @viewMap="viewMap"
                  @outMap="outMap"
                  :mapShow="mapShow"
                  :outIcon="outIcon"
                ></newMaP>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="16" class="content2-wrap">
            <el-col :span="8">
              <div class="item-wrap">
                <graphTitle title="设备类型统计"></graphTitle>
                <equipmentTypeStatistics
                  :equipmentTypeData="equipmentTypeData"
                ></equipmentTypeStatistics>
              </div>
            </el-col>
            <el-col :span="8"
              ><div class="item-wrap">
                <graphTitle title="路内道路设备类型占比"></graphTitle>
                <insideRoadParking :chartData="insideRoadData"></insideRoadParking></div
            ></el-col>
            <el-col :span="8"
              ><div class="item-wrap">
                <graphTitle title="路外车场设备类型占比"></graphTitle>
                <outsideRoadParking :chartData="outRoadData"></outsideRoadParking></div
            ></el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import graphTitle from "@/components/graphTitle";
import roadItem from "./roadItem";
import olMap from "./olMap";
import equipmentTypeStatistics from "./equipmentTypeStatistics";
import insideRoadParking from "./insideRoadParking";
import outsideRoadParking from "./outsideRoadParking";
import newMaP from "./newMaP";
/* eslint-disable */
export default {
  name: "equipmentDistribution",
  data() {
    return {
      centerHeight: { value: "508px" },
      outIcon: false,
      mapShow: true,
      // 区域列表
      areaList: [],
      // 区域街道
      areaList2: [],
      loading: false,
      formInline: {
        // 区域
        areaId: "",
        // 区域
        streetId: "",
        // 车场名称
        parkName: "",
      },
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 15,
      insideRoadData: [], // 路内
      outRoadData: [], // 路外
      equipmentTypeData: {}, // 设备类型统计
      areaData: [],
      slaveRelations: "0,2",
    };
  },
  components: {
    graphTitle,
    roadItem,
    olMap,
    equipmentTypeStatistics,
    insideRoadParking,
    outsideRoadParking,
    newMaP,
  },
  watch: {},
  methods: {
    // 区域筛选
    selectPark() {
      this.$refs.newMaP.getAreaData(
        this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
        3
      );
    },
    areaHandler(val) {
      console.log("val", val);
      this.searchData();
      this.$refs.newMaP.getAreaData(
        this.$refs.cascader.getAreaId(),
        this.$refs.parkSelect.getParkId(),
        val
      );
    },
    parkClear() {
      this.$refs.parkSelect.clear();
    },
    // 清空车场组件信息
    resetCarPark() {
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      // this.$refs.parkInput.setShowVal("");
    },

    // 清空
    resetForm() {
      this.formInline.areaId = "";
      this.formInline.streetId = "";
      this.resetCarPark();
      this.$refs.cascader.clear();
      // this.$refs.operationSelect.clear();
      // this.$refs.parkTypeSelect.clear();
      this.parkClear();
      this.searchData();
    },
    // 初始化用户区域下拉列表（区域和街道）
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },

    searchData() {
      this.loading = true;
      this.$axios
        .get("/acb/2.0/equipmentStatistics/getEquipmentCount", {
          data: {
            streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.getAreaEquipmentList();
            this.insideRoadData = res.value.inRoad;
            this.outRoadData = res.value.outRoad;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * 获取设备类型
     */
    getAreaEquipmentList() {
      this.$axios
        .get("/acb/2.0/equipmentStatistics/getAreaEquipmentNumber", {
          data: {
            streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
          },
        })
        .then((res) => {
          let legendData = [];
          let seriesData = [];
          let seriesObj = {};
          let equipmentNameList = [];
          let nameItem = [];
          let oldList = [];
          if (res.state == 0) {
            this.areaData = res.value;
            let resData = [];
            res.value.forEach((ele) => {
              legendData.push(ele.areaName);
              resData.push(ele.equipmentNumbers);
              ele.equipmentNumbers.forEach((val, i) => {
                oldList.push(val);
                equipmentNameList.push(val.equipmentTypeName);
                nameItem = Array.from(new Set(equipmentNameList));
              });
            });

            nameItem.forEach((item, i) => {
              let totalData = [];
              resData.forEach((data, ii) => {
                data.forEach((val) => {
                  if (data.length < nameItem.length) {
                    if (nameItem[i] == val.equipmentTypeName) {
                      totalData.push(val.totalEquipmentNumber);
                    } else {
                      totalData.push(0);
                    }
                  } else {
                    if (nameItem[i] == val.equipmentTypeName) {
                      totalData.push(val.totalEquipmentNumber);
                    }
                  }
                });
              });
              seriesObj = {
                name: nameItem[i],
                type: "bar",
                stack: "all",
                barWidth: 12,
                data: totalData,
              };
              seriesData.push(seriesObj);
            });
          }
          this.equipmentTypeData = {
            legendData: legendData,
            seriesData: seriesData,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * 地图全屏
     */
    viewMap() {
      let mapDom = this.$refs["map"];
      const mapWidth = mapDom.clientWidth - 32;
      this.$nextTick((_) => {
        mapDom.className = "fullscreen";
        mapDom.style.height = "100%";
        let mainWrapper = document.getElementById("home_content");
        mainWrapper.style["z-index"] = 30;
        this.outIcon = true;
        this.mapShow = false;
        sessionStorage.isfull = true;
        sessionStorage.mapWidth = mapWidth;
        this.parkRecordNumShow = false;
      }, 300);
    },

    /**
     * 地图缩小
     */
    outMap() {
      let mapDom = this.$refs.map;
      mapDom.style.height = this.centerHeight.value;
      mapDom.style.width = sessionStorage.mapWidth + "px";
      this.outIcon = false;
      this.mapShow = true;
      mapDom.className = "item-wrap";
      sessionStorage.isfull = false;
      this.parkRecordNumShow = true;
      let mainWrapper = document.getElementById("home_content");
      mainWrapper.style["z-index"] = 2;
    },
  },

  created() {
    this.getArea();
  },

  mounted() {
    this.searchData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">

.content1-wrap {
  max-height: 540px;
  overflow: hidden;

  .item-wrap:first-child {
    min-height: 508px;
  }

  .item-wrap {
    // max-height: 536px;
    overflow: hidden;
  }
}

.content2-wrap {
  .item-wrap {
    max-height: 338px;
    overflow: hidden;
  }
}

.content1-wrap, .content2-wrap {
  margin-top: 16px;

  .item-wrap {
    background: #fff;
    border-radius: 8px;
    padding: 16px;

    .line-title {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .offline, .online {
        font-size: 14px;
        color: #9AA4B3;

        span {
          vertical-align: middle;
        }
      }

      .offline {
        padding-left: 16px;
      }

      .online:before {
        content: '';
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 6px;
        background: #38CB89;
        border-radius: 4px;
      }

      .offline:before {
        content: '';
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 6px;
        background: #FF0017;
        border-radius: 4px;
      }
    }
  }
}

.fullscreen {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  box-sizing: border-box !important;
  min-width: 0 !important;
  max-width: none !important;
  min-height: 0 !important;
  max-height: none !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
  transform: none !important;
  margin: 0 !important;
  z-index: 1001;
}

.fullscreen .summarizeWrapper >>> .amap-demo {
  position: fixed;
  width: 100%;
  height: 100%;
}
</style>
