<!--
 * @Author: faf
 * @desc: 
 * @Date: 2022-12-15 16:26:01
 * @LastEditors: faf
 * @ LastEditTime: 
-->
<template>
  <div class="road-wrap">
    <div v-for="(item, ind) in roadData" :key="ind" class="item-content">
      <span class="name">{{ item.equipmentTypeName }}</span>
      <span class="count">{{ item.totalEquipmentNumber }}</span>
      <div class="line-wrap">
        <span class="online-num"
          ><span>{{ item.onlineEquipmentNumber }}</span></span
        >
        <span class="offline-num"
          ><span>{{ item.offlineEquipmentNumber }}</span></span
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    roadData: {
      type: Array,
      default: []
    }
  }
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.road-wrap {
  padding-top: 8px;
  max-height: 299px;
  overflow: auto;

  .item-content:last-child {
    margin-bottom: 0;
  }

  .item-content {
    height: 46px;
    padding: 0 12px 0 16px;
    margin-bottom: 4px;
    line-height: 46px;
    background: #F6F9FC;
    box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.06);
    font-family: PingFangSC-Medium, PingFang SC;
    font-size: 14px;
    display: flex;
    align-items: center;

    .name {
      font-weight: 500;
      color: #344E6E;
      line-height: 22px;
    }

    .count {
      padding-left: 5px;
      color: #7A828A;
    }

    .line-wrap {
      flex: 1;
      justify-content: flex-end;
      display: flex;
      align-items: center;
      line-height: 28px;
    }

    .online-num, .offline-num {
      height: 28px;
      background: rgba(39, 39, 46, 0.04);
      border-radius: 4px;
      padding: 0 16px 0 12px;
      color: #7A828A;

      span {
        vertical-align: middle;
      }
    }

    .offline-num {
      margin-left: 8px;
    }

    .online-num:before {
      content: '';
      width: 8px;
      height: 8px;
      display: inline-block;
      margin-right: 8px;
      background: #38CB89;
      border-radius: 4px;
    }

    .offline-num:before {
      content: '';
      width: 8px;
      height: 8px;
      display: inline-block;
      margin-right: 8px;
      background: #FF0017;
      border-radius: 4px;
    }
  }
}
</style>
