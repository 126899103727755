<template>
  <div style="width: 100%; height: 295px" id="equipmentId"></div>
</template>
<script>
export default {
  props: {
    titleText: {
      type: String,
      default: "各区新能源车入场对比"
    },
    equipmentTypeData: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  watch: {
    equipmentTypeData: {
      handler(val, oldVal) {
        this.$nextTick(() => {
          this.drawPie();
        });
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      colors: [
        "#4C6FFF",
        "#F7936F",
        "#10CDFF",
        "#FF00F4",
        "#FFD14D",
        "#9B88FF",
        "#2BE558",
        "#FF95B0",
        "#66CB9F",
        "#94E0FF",
        "#FF0000"
      ]
    };
  },
  methods: {
    // 绘制饼图
    drawPie() {
      console.log(this.getOption());
      this.pieDom.setOption(this.getOption(), true);
      window.addEventListener("resize", () => {
        this.pieDom.resize();
      });
    },
    getOption() {
      return {
        backgroundColor: "#fff",
        color: [
          "#5A90FF",
          "#95AEFF",
          "#AE7CE6",
          "#FF7E08",
          "#EBF8FF",
          "#9A95FF"
        ],
        legend: {
          orient: 'horizontal',
          itemWidth: 16,
          itemHeight: 5,
          left: "0",
          top: "5px",
          textStyle: {
            color: "#9AA4B3"
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          },
          backgroundColor: "#FFF",
          boxShadow: "0px 8px 20px 0px rgba(0,35,114,0.10)",
          padding: 15,
          margin: [30, 20],
          textStyle: {
            color: "#7a828a",
            fontSize: 12
          },
          formatter: function (params) {
            let result = "";
            let val = "";
            params.forEach(function (item) {
              val = item.name + "<br>";
              result += `<span style='display:inline-block;margin-right:5px;border-radius:8px;width:8px;height:8px;background-color: ${item.color}'></span>`;
              result += item.seriesName + ": " + item.data + "<br>";
            });
            return val + result;
          }
        },
        grid: {
          left: "7px",
          right: "0",
          bottom: "0px",
          top: "85px",
          containLabel: true
        },
        xAxis: {
          type: "category",
          data: this.equipmentTypeData.legendData,
          axisTick: {
            alignWithLabel: true,
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#B0B7C3",
              fontSize: "11"
            }
          }
        },
        yAxis: {
          type: "value",
          name: "设备数量",
          nameTextStyle: {
            color: "#9AA4B3",
            fontSize: 12,
            padding: [0, 0, 0, 50]
          },
          nameGap: 22,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#B0B7C3"
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#DAE3EC"
            }
          },
          axisLabel: {
            interval: 0,
            inside: true,
            color: "#9AA4B3",
            margin: 0,
            padding: [0, 0, 5, 0],
            align: "left",
            verticalAlign: "bottom",
            formatter: function (value, index) {
              return "{title|" + value + "}";
            },
            rich: {
              title: {
                fontSize: 12
              }
            }
          }
        },
        series: this.equipmentTypeData.seriesData
        // series: [
        //   {
        //     name: "高位视频",
        //     type: "bar",
        //     stack: "all",
        //     barWidth: 12,
        //     data: [5, 0]
        //   },
        //   {
        //     name: "视频桩",
        //     type: "bar",
        //     stack: "all",
        //     barWidth: 12,
        //     data: [3, 1]
        //   }
        // ]
      };
    }
  },
  mounted() {
    this.pieDom = this.$echarts.init(document.getElementById("equipmentId"));
    this.drawPie();
  },
  created() {}
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
