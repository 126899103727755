<template>
  <div style="width: 100%; height: 295px" id="insideRoadId"></div>
</template>
<script>
export default {
  props: {
    titleText: {
      type: String,
      default: "路内车场设备类型占比"
    },
    chartData: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  watch: {
    chartData: {
      handler(val, oldVal) {
        let data = [];
        let totalData = 0;
        val.forEach(el => {
          totalData += el.totalEquipmentNumber;
          data.push({
            name: el.equipmentTypeName,
            value: el.totalEquipmentNumber
          })
        });
        this.totalData = totalData;
        this.dataList = data;
        this.$nextTick(() => {
          this.drawPie();
        });
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      dataList: [],
      totalData: 0,
      colors: [
        "#527BFF",
        "#95AEFF",
        "#AE7CE6",
        "#FF7E08",
        "#DDFF95",
        "#EBF9FF"
      ],
      pieImageUrl: require("./img/pie_chart.png")
    };
  },
  methods: {
    // 绘制饼图
    drawPie() {
      console.log(this.getOption());
      this.pieDom.setOption(this.getOption(), true);
      window.addEventListener("resize", () => {
        this.pieDom.resize();
      });
    },
    getOption() {
      return {
        color: this.colors,
        title: {
          text: this.totalData + "台",
          subtext: "设备总数",
          x: "center",
          y: "115px",
          textStyle: {
            color: "#425466",
            fontSize: 14
          },
          subtextStyle: {
            color: "#7A828A",
            fontSize: 12
          }
        },
        tooltip: {
          show: true,
          formatter: function(val) {
            let html = ``;
            html = `${val.name}： ${val.value}台</br>占比：${val.percent}%`; 
            return html;
          }
        },
        legend: {
          itemWidth: 19,
          itemHeight: 7,
          bottom: "0px",

          formatter: ["{a|{name}}"].join("\n"),
          textStyle: {
            color: "#9AA4B3",
            backgroundColor: "transparent", // 文字块背景色，一定要加上，否则对齐不会生效
            rich: {
              a: {
                width: 60
              }
            }
          }
        },
        graphic: {
          elements: [
            {
              type: "image",
              z: 2,
              style: {
                image: this.pieImageUrl,
                width: 156,
                height: 156
              },
              left: "center",
              top: "52px"
            }
          ]
        },
        series: [
          {
            type: "pie",
            center: ["50%", "45%"],
            radius: ["58%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 10
            },
            normal: {
              show: false
            },
            label: {
              show: false,
              position: "center",
              z: 3,
              fontSize: 20,
              emphasis: {
                show: false
              }
            },
            labelLine: {
              show: false
            },
            data: this.dataList
          }
        ]
      };
    }
  },
  mounted() {
    this.pieDom = this.$echarts.init(document.getElementById("insideRoadId"));
    this.drawPie();
  },
  created() {}
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
